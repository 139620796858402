<template>
  <b-overlay :show="loading">
    <div class="pt-5 pb-5 px-2">
      <list-events
        :items="events"
        :per-page="perPage"
        :total-records="totalRecords"
        :current-page.sync="currentPage"
        detail-page="single-event"
      />
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BLink,
  BImg,
} from 'bootstrap-vue';

import { get } from "lodash";

import ListEvents from "@/@core/components/shared/events/ListEvents.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const watchHandler = {
  handler(){
    this.fetchAllData()
  }, 
  immediate: false
}

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BImg,

    ListEvents
  },
  data() {
    return {
      loading: false,
      events: [],
      perPage: 50,
      currentPage: 1,
      totalRecords: 0,
    }
  },
  watch: {
    perPage: watchHandler,
    currentPage: watchHandler,
    searchFilter: watchHandler,
  },
  created() {
    this.fetchAllData();
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: 12,
          page: this.currentPage,
        }

        const request = await this.useJwt().sharedService.fetchEvents(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;

        this.events = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
